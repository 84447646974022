import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';


//获取销售订单列表新
export const saleOrderList = (page, pageSize, businessnoin, businessnoout, so, from, code, batch, color, com, beginOrderDate, endOrderDate, otherStatus, traingStatus, UTbegin, UTend, sort, SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrders",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "so": so,
            "from": from,
            "code": code,
            "batch": batch,
            "color": color,
            "completion": com,
            "beginOrderDate": beginOrderDate,
            "endOrderDate": endOrderDate,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "sort": sort,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//获取销售订单列表Excel
export const saleOrderListExcel = (page, pageSize, businessnoin, businessnoout, so, from, code, batch, color, com, beginOrderDate, endOrderDate, status, otherStatus, traingStatus, UTbegin, UTend, sort, SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrdersExcel",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "so": so,
            "from": from,
            "code": code,
            "batch": batch,
            "color": color,
            "completion": com,
            "beginOrderDate": beginOrderDate,
            "endOrderDate": endOrderDate,
            "status": status,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "sort": sort,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//获取销售单详情
export const SaleInfo = (id,orderId, brandBusinessId,vendorSectionId) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderCanRead",
        method: "get",
        params: {
            "id": id,
            "orderId": orderId,
            "brandBusinessId": brandBusinessId,
            "vendorSectionId": vendorSectionId
        }
    })
}

//根据Batch号匹配数据
export const CodeList = (Code) => {
    return request({
        url: pvhApiBase + "Tracing/GetCodeList",
        method: "get",
        params: {
            "Code": Code
        }
    })
}

//判断是否能关联选择品牌商关联是否正常
export const verificationInScope = (PurchaseOrderDetailId, SaleOrderDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/GetVerificationInScope",
        method: "get",
        params: {
            "PurchaseOrderDetailId": PurchaseOrderDetailId,
            "SaleOrderDetailId": SaleOrderDetailId
        }
    })
}

//销售单选择可以使用的采购单
export const CanUesPos = (page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrdersCanUesPos",
        method: "get",
        params: {
            "page": page,
            "pageSize": pageSize
        }
    })
}


//获取能使用的po库存信息
export const poCanUserStockList = (page, pageSize, vendor, code, po, color, beginTime, endTime, vendorSectionId,SeaCode) => {
    return request({
        url: pvhApiBase + "Tracing/GetPoCanUserStocks",
        method: "get",
        params: {
            "code": code,
            "po": po,
            "color": color,
            "vendor": vendor,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "vendorSectionId": vendorSectionId,
            "SeaCode": SeaCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//获取能po库存最新剩余数量
export const stockNum = (PurchaseOrderDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/GetStockNum",
        method: "get",
        params: {
            "PurchaseOrderDetailId": PurchaseOrderDetailId
        }
    })
}
//验证使用数量大于库存
export const checkInventory = (PurchaseOrderDetailId, DetailId, num, way) => {
    return request({
        url: pvhApiBase + "Tracing/CheckInventory",
        method: "get",
        params: {
            "Id": PurchaseOrderDetailId,
            "DetailId": DetailId,
            "Num": num,
            "Way": way
        }
    })
}

//获取SO已使用的库存
export const soUserStockList = (page, pageSize, soDetailID) => {
    return request({
        url: pvhApiBase + "Tracing/GetSoUserStocks",
        method: "get",
        params: {
            "soDetailID": soDetailID,
            "page": page,
            "pageSize": pageSize
        }
    })
}



//新增
export const addRalation = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PostOrderRalation",
        method: "post",
        data: {
            ...params
        }
    })
}

//编辑
export const editRalation = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutOrderRalation",
        method: "put",
        data: {
            ...params
        }
    })
}

//删除
export const delRalation = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteOrderRalation",
        method: "delete",
        params: {
            id
        }
    })
}



//编辑
export const editSale = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutSaleOrder",
        method: "put",
        data: {
            ...params
        }
    })
}

//编辑
export const editHeadSO = (params) => {
    console.log(params);
    return request({
        url: pvhApiBase + "Tracing/PutSoInfo",
        method: "put",
        data: {
            ...params
        }
    })
}

//编辑
export const editDetailSO = (params) => {
    console.log(params);
    return request({
        url: pvhApiBase + "Tracing/PutSoDetailInfo",
        method: "put",
        data: {
            ...params
        }
    })
}

export const PutSaleOrderDetail_Copy = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutSaleOrderDetail_Copy",
        method: "put",
        data: {
            ...params
        }
    })
}

export const PutSaleOrderDetail_CancelCopy = (id) => {
    return request({
        url: pvhApiBase + "Tracing/PutSaleOrderDetail_CancelCopy",
        method: "delete",
        params: {
            id
        }
    })
}


//
export const SoSubmitAudit = (soDetailId, poDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/SoSubmitAudit",
        method: "get",
        params: {
            "soDetailId": soDetailId,
            "poDetailId": poDetailId,
        }
    })
}



export const ReducingStateSubmitAudit = (soDetailId, poDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/ReducingStateSubmitAudit",
        method: "get",
        params: {
            "soDetailId": soDetailId,
            "poDetailId": poDetailId,
        }
    })
}


